import request from '@/utils/request'

// 首页模块
export function getClassify() {
    return request({
        method: 'POST',
        url: `/homepage/getClassify`,

    })
}

// 热门推荐
export function getRecommend() {
    return request({
        method: 'POST',
        url: `/homepage/getRecommend`,
    })
}

// 获取资源列表(更多，下部分)
export function getResList(data) {
    return request({
        method: 'POST',
        url: `/resList/getResListPC`,
        data
    })
}

// 上部分
export function getFuncList() {
    return request({
        method: 'POST',
        url: `/resList/getFuncList`,

    })
}

  // 资源详情列表
  export function getDetailRes(data){
    return request({
      method: 'POST',
      url:`/detail/getDetailRes` ,
      data
    })
  }

   // 首页播图
   export function getBanner(){
    return request({
      method: 'POST',
      url:`/homepage/getBanner` ,
    })
  }

    // 搜索
    export function queryRes(data){
      return request({
        method: 'POST',
        url:`/homepage/queryResPC` ,
        data
      })
    }
    export function addAccessStatistics(data) {
      return request({
        method: 'POST',
        url: `/accessStatistics/addAccessStatistics`,
        data
      })
    }
    
