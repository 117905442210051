export default {
    // 时间戳转换
    time (value) {
      const date = new Date(value * 1000)// 如果date为13位不需要乘1000
      const Y = date.getFullYear() + '-'
      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      const D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' '
      return Y + M + D
    },
    getUrl () {
      return 'https://historystory.oss-cn-hangzhou.aliyuncs.com'
    },
    getBaseUrl(){
        return 'https://121.43.140.162:6699'
    }
  }
